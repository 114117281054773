import React, { useState } from "react";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { MdHomeFilled, MdPeople, MdMail, MdArticle } from "react-icons/md";
import { HiGift } from "react-icons/hi";

import { ChineseNYHat, IconFAQ, IconMenu } from "./svg";
import { useScrollDirection } from "../utils/Utils";
import { DataMenu } from "../utils/dummyData";
import { FaPeopleCarry } from "react-icons/fa";

const transition = {
  type: "tween",
  duration: 0.5,
  ease: [0.7, -0.4, 0.4, 1.2],
};

const variants = {
  show: {
    y: 0,
  },
  hidden: {
    y: "-12.5vh",
  },
  hidden2: {
    y: -320,
  },
};

function Navbar({ activeNav }) {
  const [open, setOpen] = useState(false);
  const scrollDirection = useScrollDirection();

  const handleOpen = () => {
    setOpen(!open);
  };

  const ListIcon = {
    home: <MdHomeFilled className="w-6 h-6" />,
    "about-us": <MdPeople className="w-6 h-6" />,
    "our-product": <HiGift className="w-6 h-6" />,
    article: <MdArticle className="w-6 h-6" />,
    activity: <FaPeopleCarry className="w-6 h-6" />,
    "contact-us": <MdMail className="w-6 h-6" />,
    faq: (
      <IconFAQ
        className="w-6 h-[22px]"
        color={activeNav === "faq" ? "#f79e0e" : "#ffffff"} // Default Base Theme
        // color={activeNav === "faq" ? "#995AA8" : "#ffffff"} // Valentine Theme
        // color={activeNav === "faq" ? "#fbbf24" : "#ffffff"} // Independence Day Theme
      />
    ),
  };

  return (
    <motion.header
      // className={`navbar flex flex-row justify-between items-center navbar-primary`}
      // className={`navbar flex flex-row justify-between items-center navbar-halloween`} // Halloween Theme
      // className={`navbar flex flex-row justify-between items-center navbar-ramadhan`} // Ramadhan Theme
      // className={`navbar flex flex-row justify-between items-center navbar-independenceDay`} // Independence Day Theme
      // className={`navbar flex flex-row justify-between items-center navbar-christmas`} // Christmas Day Theme
      // className={`navbar flex flex-row justify-between items-center navbar-chinese-ny`} // Chinese New Year Theme
      className={`navbar flex flex-row justify-between items-center navbar-chinese-ny-v2`} // Chinese New Year Theme V2
      // className={`navbar flex flex-row justify-between items-center navbar-valentine`} // Valentine Theme
      variants={variants}
      initial={{ y: 0 }}
      animate={scrollDirection === "up" ? "show" : open ? "hidden2" : "hidden"}
      transition={{
        duration: 0.25,
      }}
    >
      {/* <IndependenceDecoration className="w-10 md:w-12 lg:w-14 xl:w-16 h-auto absolute right-0 top-0" /> // Independence Day Theme */}

      <nav className="w-4/12 h-[3.6vh] lg:h-[5.4vh] xl:h-[6vh] relative">
        <Link to="/">
          <img
            className="navbar__brand w-auto h-[3.6vh] lg:h-[5.4vh] xl:h-[6vh] absolute top-0"
            src={`/images/universal/logo-light.png`}
            alt="DHC Brand"
          />

          {/* Independence Day Theme */}
          {/* <IndonesianFlag className="w-7 md:w-8 lg:w-9 xl:w-10 h-auto absolute -left-4 md:-left-5 lg:-left-6 xl:-left-8 bottom-0" /> */}
          {/* Christmas Theme */}
          {/* <SantaHat className=" w-8 md:w-9 lg:w-10 xl:w-11 h-auto absolute -left-3 lg:-left-3 xl:-left-4 bottom-[1.8vh] lg:bottom-[2.7vh] xl:bottom-[3vh]" /> */}
          {/* Chinese New Year Theme */}
          <ChineseNYHat className="w-8 md:w-9 lg:w-10 xl:w-11 h-auto absolute -left-4 bottom-[1.8vh] lg:bottom-[3.2vh] xl:bottom-[4vh]" />
        </Link>
      </nav>
      <div className="flex lg:hidden">
        <IconMenu
          open={open}
          handleOpen={handleOpen}
          className="w-6 h-6 cursor-pointer overflow-hidden"
        />
      </div>

      {/* Navbar Mobile Screen */}
      <motion.nav
        // Basic Theme
        // className={`navbar__mobile flex sm:hidden flex-col justify-center space-y-4 w-full absolute bg-primary text-white left-0 top-[7.9vh]`}
        // Independence Day Theme
        // className={`navbar__mobile flex sm:hidden flex-col justify-center space-y-4 w-full absolute bg-[#961414] text-white left-0 top-[7.9vh]`}
        // Halloween Theme
        // className={`navbar__mobile flex sm:hidden flex-col justify-center space-y-4 w-full absolute bg-[#510952] text-white left-0 top-[7.9vh]`}
        // Christmas Theme
        // className={`navbar__mobile flex lg:hidden flex-col justify-center space-y-4 w-full absolute bg-[#0B4BAC] text-white left-0 top-[7.9vh]`}
        // Chinese New year Theme
        className={`navbar__mobile flex lg:hidden flex-col justify-center space-y-4 w-full absolute bg-[#840002] text-white left-0 top-[7.9vh]`}
        // Valentine Theme
        // className={`navbar__mobile flex lg:hidden flex-col justify-center space-y-4 w-full absolute bg-[#FC86BC] text-white left-0 top-[7.9vh]`}
        // Ramadhan Theme
        // className={`navbar__mobile flex lg:hidden flex-col justify-center space-y-4 w-full absolute bg-[#2D6259] text-white left-0 top-[7.9vh]`}
        initial={{ height: 0 }}
        animate={open ? { height: "auto" } : { height: 0 }}
        transition={{ ease: "easeOut", duration: 0.4 }}
      >
        {DataMenu.map((menu, index) => {
          const activeMenu = "text-secondary"; // Default
          // const activeMenu = "text-[#995AA8]"; // Valentine
          // const activeMenu = "text-[#F6E295]"; // Ramadhan
          // const activeMenu = "text-amber-400"; // Independence Day

          return (
            <Link
              key={index}
              to={menu.slug === "home" ? "/" : `/${menu.slug}`}
              className={`flex items-center space-x-3 pl-4 capitalize ${
                activeNav === menu.slug ? activeMenu : ""
              } ${
                index === 0
                  ? "pt-[4vw]"
                  : index === DataMenu.length - 1
                  ? "pb-[4vw]"
                  : ""
              }`}
            >
              {ListIcon[menu.slug]}
              <h5 className="text-xl leading-none">{menu.name}</h5>
            </Link>
          );
        })}
      </motion.nav>

      {/* Navbar Laptop Screen */}
      <nav className="hidden lg:flex flex-row space-x-8 xl:space-x-9 text-base xl:text-lg font-medium">
        {DataMenu.filter((item) => item.menu_header).map((menu, index) => {
          const activeMenu = "text-secondary"; // Default
          // const activeMenu = "text-[#995AA8]"; // Valentine
          // const activeMenu = "text-[#F6E295]"; // Ramadhan
          // const activeMenu = "text-amber-400"; // Independence Day

          return (
            <Link
              key={index}
              to={menu.slug === "home" ? "/" : `/${menu.slug}`}
              className={`relative ${
                activeNav === menu.slug ? activeMenu : ""
              } ${DataMenu.length - 1 === index ? "pb-4" : ""}`}
            >
              {menu.name.toUpperCase()}
              {activeNav === menu.slug && (
                <motion.div
                  className="navbar-underline"
                  layoutId="navbar-underline"
                  transition={transition}
                />
              )}
            </Link>
          );
        })}
      </nav>
    </motion.header>
  );
}

export default Navbar;

import React from "react";

const ChineseNYHat = (props) => {
  return (
    <svg
      viewBox="0 0 45 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g>
          <path
            d="M15.4042 19.9319C16.2542 19.7406 16.8314 19.0836 16.6916 18.4631C16.5519 17.8427 15.7501 17.495 14.9001 17.6863C14.0501 17.8776 13.4729 18.5347 13.6127 19.1551C13.7524 19.7756 14.5541 20.1232 15.4042 19.9319Z"
            fill="black"
          />
          <path
            d="M14.9378 20.1268C14.67 20.1268 14.4122 20.0769 14.186 19.9788C13.805 19.8141 13.5522 19.533 13.474 19.187C13.3958 18.841 13.504 18.48 13.7784 18.1673C14.0412 17.8679 14.4288 17.6483 14.8696 17.5485C15.7961 17.3406 16.6744 17.7365 16.8308 18.4335C16.909 18.7794 16.8008 19.1404 16.5264 19.4531C16.2636 19.7525 15.876 19.9721 15.4352 20.0719C15.2688 20.1102 15.1008 20.1285 14.9378 20.1285V20.1268ZM15.367 17.7747C15.2256 17.7747 15.0792 17.7914 14.9312 17.8246C14.5486 17.9111 14.2159 18.0991 13.9913 18.3536C13.7784 18.5965 13.6936 18.8693 13.7501 19.1238C13.8067 19.3783 14.0013 19.5895 14.2974 19.7176C14.6085 19.8524 14.9911 19.879 15.372 19.7941C15.7546 19.7076 16.0872 19.5197 16.3118 19.2652C16.5247 19.0223 16.6096 18.7495 16.553 18.495C16.4549 18.0559 15.9608 17.7764 15.367 17.7764V17.7747Z"
            fill="black"
          />
        </g>
        <g>
          <path
            d="M12.4999 20.6722C13.3499 20.4809 13.9271 19.8238 13.7873 19.2034C13.6476 18.5829 12.8458 18.2352 11.9958 18.4265C11.1458 18.6178 10.5686 19.2749 10.7084 19.8953C10.8481 20.5158 11.6498 20.8634 12.4999 20.6722Z"
            fill="black"
          />
          <path
            d="M12.0335 20.8668C11.7657 20.8668 11.5079 20.8169 11.2817 20.7188C10.9007 20.5541 10.6479 20.273 10.5697 19.927C10.4915 19.581 10.5997 19.22 10.8741 18.9073C11.1369 18.6079 11.5245 18.3883 11.9653 18.2885C12.4061 18.1887 12.8503 18.222 13.2162 18.38C13.5971 18.5447 13.85 18.8258 13.9282 19.1718C14.0063 19.5178 13.8982 19.8787 13.6238 20.1915C13.3609 20.4909 12.9734 20.7105 12.5325 20.8103C12.3662 20.8485 12.1982 20.8668 12.0352 20.8668H12.0335ZM12.4627 18.5147C12.3196 18.5147 12.1733 18.5314 12.0269 18.5646C11.6443 18.6511 11.3116 18.8391 11.087 19.0936C10.8741 19.3365 10.7893 19.6093 10.8458 19.8638C10.9024 20.1183 11.097 20.3295 11.3931 20.4576C11.7042 20.5924 12.0868 20.619 12.4677 20.5341C12.8503 20.4476 13.1829 20.2597 13.4075 20.0052C13.6204 19.7623 13.7053 19.4895 13.6487 19.235C13.5921 18.9805 13.3975 18.7692 13.1014 18.6412C12.9101 18.558 12.6906 18.5164 12.4627 18.5164V18.5147Z"
            fill="black"
          />
        </g>
        <g>
          <path
            d="M10.1224 21.547C10.9126 21.1778 11.3334 20.4109 11.0639 19.8354C10.7944 19.2598 9.93613 19.0918 9.146 19.4611C8.35588 19.8304 7.93503 20.5972 8.20451 21.1728C8.47398 21.7483 9.33231 21.9163 10.1224 21.547Z"
            fill="black"
          />
          <path
            d="M9.27669 21.8848C9.16191 21.8848 9.05046 21.8748 8.94234 21.8532C8.5348 21.7733 8.22707 21.5537 8.07736 21.2327C7.92766 20.9117 7.95593 20.5357 8.15554 20.1714C8.3485 19.8221 8.67952 19.5244 9.08706 19.3331C9.94705 18.9305 10.8902 19.1301 11.1929 19.7756C11.3427 20.0966 11.3144 20.4725 11.1148 20.8368C10.9218 21.1861 10.5908 21.4839 10.1833 21.6752C9.88716 21.8132 9.57444 21.8848 9.27669 21.8848ZM9.99861 19.4063C9.74744 19.4063 9.47464 19.4662 9.20849 19.5893C8.85418 19.7556 8.56807 20.0101 8.40506 20.3078C8.25036 20.5906 8.22541 20.8767 8.33519 21.1113C8.44498 21.3475 8.68119 21.5122 8.99723 21.5737C9.32992 21.6386 9.70918 21.5837 10.0635 21.4173C10.4178 21.251 10.7039 20.9965 10.8669 20.6988C11.0216 20.416 11.0466 20.1315 10.9368 19.8953C10.7887 19.5776 10.4261 19.4063 9.99861 19.4063Z"
            fill="black"
          />
        </g>
        <g>
          <path
            d="M7.42152 23.0092C8.19001 22.5983 8.57094 21.8115 8.27152 21.251C7.97211 20.6904 7.10547 20.569 6.33697 20.9798C5.56847 21.3907 5.18754 22.1775 5.48696 22.7381C5.78637 23.2986 6.65302 23.4201 7.42152 23.0092Z"
            fill="black"
          />
          <path
            d="M6.49639 23.3955C6.41655 23.3955 6.3367 23.3905 6.25852 23.3788C5.84766 23.3206 5.52828 23.1177 5.36194 22.8049C5.02593 22.1762 5.43346 21.3012 6.27016 20.8537C7.10686 20.4063 8.06167 20.556 8.39601 21.1848C8.73202 21.8135 8.32449 22.6885 7.48779 23.136C7.16841 23.3073 6.82408 23.3955 6.49639 23.3955ZM7.26489 20.877C6.99209 20.877 6.69101 20.9519 6.40324 21.1049C5.7046 21.4775 5.35029 22.1812 5.61145 22.6719C5.73454 22.9014 5.9774 23.0528 6.29678 23.0977C6.63279 23.1443 7.00706 23.0694 7.35305 22.8848C8.05169 22.5122 8.40599 21.8086 8.14484 21.3178C7.99014 21.0284 7.65579 20.877 7.26489 20.877Z"
            fill="black"
          />
        </g>
        <g>
          <path
            d="M2.57117 22.5505C3.76051 21.915 5.48714 21.8169 5.79155 22.4008C6.04106 22.8815 5.37403 23.9061 4.51903 24.3702C3.70728 24.811 3.19329 24.49 2.25346 24.7928C1.69122 24.9741 0.909414 25.4016 0.142578 26.5427C0.217432 26.0204 0.616652 23.5934 2.57117 22.5521V22.5505Z"
            fill="black"
          />
          <path
            d="M0.142968 26.6844C0.126334 26.6844 0.108036 26.681 0.0914021 26.6744C0.0298556 26.6511 -0.00840302 26.5879 0.00157749 26.5213C0.129661 25.6231 0.63035 23.4241 2.50336 22.4243C3.40826 21.9419 4.68244 21.739 5.40436 21.9619C5.6572 22.0401 5.8302 22.1648 5.91669 22.3328C6.23108 22.9367 5.44761 24.0245 4.58596 24.492C4.10856 24.7515 3.7293 24.7631 3.32841 24.7764C3.01902 24.7864 2.69798 24.7964 2.29543 24.9261C1.53359 25.1723 0.849921 25.7412 0.259407 26.6195C0.232793 26.6594 0.18788 26.6827 0.141305 26.6827L0.142968 26.6844ZM4.69242 22.1532C4.052 22.1532 3.25522 22.3462 2.63643 22.6755C1.20922 23.4374 0.628686 24.9594 0.394144 25.9624C0.933092 25.3104 1.5419 24.8712 2.20727 24.6566C2.64808 24.5153 3.00405 24.5036 3.31843 24.4936C3.70102 24.482 4.03038 24.472 4.44956 24.2441C5.27794 23.795 5.85348 22.8302 5.66385 22.4659C5.61395 22.3694 5.49419 22.2879 5.31953 22.2347C5.1432 22.1798 4.92862 22.1532 4.69242 22.1532Z"
            fill="black"
          />
        </g>
        <g>
          <g>
            <path
              d="M17.5701 15.2886C17.1127 8.67648 22.1029 2.94434 28.7167 2.48856C35.3288 2.03112 41.0609 7.02304 41.5167 13.6351"
              fill="#CB352B"
            />
            <path
              d="M17.0541 15.3233C16.8229 11.9882 17.9058 8.76115 20.1015 6.23941C22.2972 3.71767 25.3446 2.20229 28.6797 1.97108C32.0165 1.73986 35.2419 2.82275 37.7636 5.01846C40.2854 7.21417 41.8008 10.2616 42.032 13.5967L40.999 13.6682C40.7877 10.6075 39.3971 7.81301 37.0833 5.79861C34.7695 3.78421 31.8103 2.79114 28.7496 3.0024C25.6889 3.21365 22.8944 4.60427 20.88 6.91809C18.8656 9.2319 17.8725 12.1911 18.0837 15.2518L17.0508 15.3233H17.0541Z"
              fill="black"
            />
          </g>
          <g>
            <path
              d="M35.3989 11.6918C35.2874 10.6189 34.8815 8.1454 33.0202 5.76339C31.563 3.89869 29.868 2.92892 28.9165 2.47314L28.7186 2.48645C27.8386 3.06865 26.2933 4.26132 25.1056 6.30899C23.5903 8.92388 23.5287 11.429 23.5653 12.5085"
              fill="#CB352B"
            />
            <path
              d="M23.0482 12.5266C22.9933 10.9331 23.2278 8.51611 24.6584 6.05092C25.5799 4.46069 26.8857 3.08005 28.4327 2.05539L28.5458 1.98053L29.0149 1.94727L29.138 2.00549C30.8114 2.80725 32.2951 3.99493 33.4263 5.44377C35.1812 7.68939 35.7467 10.0514 35.9114 11.6367L34.8817 11.7431C34.7304 10.2926 34.2147 8.13186 32.6112 6.0792C31.6148 4.80336 30.3173 3.74708 28.8535 3.01684C27.5045 3.9417 26.365 5.16598 25.5533 6.56658C24.2475 8.82051 24.0329 11.0312 24.0828 12.4883L23.0482 12.5233V12.5266Z"
              fill="black"
            />
          </g>
        </g>
        <g>
          <path
            d="M41.9808 10.644C38.3296 10.0552 34.7633 9.52455 29.0977 9.92876C23.5552 10.323 20.0304 11.331 16.5438 12.4006L15.8984 19.3221C19.7326 18.4272 24.3469 17.6221 29.605 17.2511C34.9762 16.8719 39.7519 17.0332 43.7341 17.3992L41.9825 10.6424L41.9808 10.644Z"
            fill="#ECC89D"
          />
          <path
            d="M15.8969 19.8972C15.7588 19.8972 15.6241 19.8473 15.5193 19.7558C15.3795 19.6344 15.308 19.4531 15.3246 19.2684L15.97 12.3469C15.9917 12.1157 16.1514 11.9194 16.3743 11.8512C20.0704 10.7168 23.5769 9.74534 29.0562 9.35444C34.9813 8.93193 38.7489 9.53908 42.0724 10.0764C42.297 10.113 42.4799 10.2793 42.5382 10.4989L44.2897 17.2557C44.3363 17.437 44.2931 17.6299 44.1716 17.773C44.0502 17.9161 43.8672 17.9909 43.6809 17.9726C39.03 17.5468 34.3076 17.4969 29.645 17.8262C25.0706 18.1489 20.4896 18.8409 16.0299 19.8839C15.9867 19.8939 15.9434 19.8988 15.8985 19.8988L15.8969 19.8972ZM17.0795 12.8376L16.5439 18.5848C20.8172 17.6283 25.1937 16.9862 29.5635 16.6785C34.0165 16.3641 38.5227 16.3907 42.9723 16.7567L41.5202 11.1509C38.348 10.6403 34.7134 10.103 29.1393 10.5005C23.9611 10.8698 20.576 11.7714 17.0812 12.836L17.0795 12.8376Z"
            fill="black"
          />
        </g>
        <g>
          <path
            d="M29.5147 1.14931C29.5479 1.64002 29.1786 2.06586 28.6863 2.09912C28.1956 2.13239 27.7697 1.76311 27.7365 1.27074C27.7032 0.780033 28.0725 0.354198 28.5648 0.32093C29.0555 0.287662 29.4814 0.65694 29.5147 1.14931Z"
            fill="#BE1E2D"
          />
          <path
            d="M28.625 2.42325C28.3322 2.42325 28.0544 2.31845 27.8315 2.1255C27.587 1.91258 27.4406 1.61816 27.419 1.29545C27.3724 0.628422 27.8781 0.0495519 28.5435 0.00297617C29.2055 -0.0435995 29.7894 0.46208 29.8359 1.12745C29.8576 1.45015 29.7528 1.76287 29.5415 2.0074C29.3303 2.25192 29.0342 2.3983 28.7115 2.41992C28.6832 2.42159 28.6549 2.42325 28.6266 2.42325H28.625ZM28.6266 0.640065C28.6133 0.640065 28.6 0.640065 28.5867 0.640065C28.4353 0.650046 28.2956 0.71991 28.1958 0.834685C28.096 0.949461 28.0461 1.09751 28.0561 1.24888C28.0661 1.40025 28.1359 1.53997 28.2507 1.63978C28.3655 1.73959 28.5119 1.78949 28.6649 1.77784C28.8163 1.76786 28.956 1.698 29.0558 1.58322C29.1556 1.46845 29.2055 1.3204 29.1955 1.16903C29.1756 0.867954 28.9227 0.636739 28.6266 0.636739V0.640065Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  );
};

export default ChineseNYHat;
